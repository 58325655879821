<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Quadratic Equations</h2>
      <p class="mb-3">
        Linear functions grow at a constant rate. For instance, if a car is travelling at a constant
        velocity of 50 km/h, the distance travelled is going to increase 50 km for every hour of
        travel time. While there are many linear relationships in chemistry, there are also many
        higher order relationships as well (i.e. quadratic, cubic, quartic, etc.). In freshman
        chemistry, you often encounter quadratic equations that you must solve by hand. The general
        form for a quadratic equation is as follows:
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex content="$ax^2 + bx + c = 0\hspace{1.3 cm}\text{where }a\neq0$" />
      </p>

      <p class="mb-3">
        In the event that you have an equation that can be easily factored or if you have a
        quadratic of the form
        <stemble-latex content="$ax^2 = c$" />, then the problem can be solved rather quickly.
        Oftentimes, however, the quadratic formula must be used. The solution to any quadratic
        formula can be obtained from:
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex content="$x = \displaystyle{\frac{-b \pm \sqrt{b^2-4ac}}{2a}}$" />
      </p>

      <p class="mb-3">
        Note the +/- which indicates that there are generally two different solutions to this
        equation. When we are dealing with quadratic equations related to chemical problems,
        generally only one of these is physically realistic. For instance, if one of the answers
        results in a concentration that is negative, then that result is not physically meaningful
        and only the other solution would be considered. Mathematically, however, both results are
        valid. Using this knowledge regarding quadratic equations, determine the
        <b>most positive</b>
        value of x for each of the expressions shown below. Be sure to include a minimum of 3
        significant figures in your answers.
      </p>

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{a) }x^2 = $" />
        <number-value :value="c1" />
      </p>

      <calculation-input
        v-model="inputs.x1"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{b) }16x^2 = $" />
        <number-value :value="c2" />
      </p>

      <calculation-input
        v-model="inputs.x2"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex :content="`$\\text{c) }x^2 + ${coefb3}x ${coefc3}=0$`" />
      </p>

      <calculation-input
        v-model="inputs.x3"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex
          :content="`$\\text{d) }${K4val} = \\displaystyle{\\frac{x^2}{${conc4val}-x}}$`"
        />
      </p>

      <calculation-input
        v-model="inputs.x4"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex
          :content="`$\\text{e) }${K5val} = \\displaystyle{\\frac{x^2}{(${conc5aval}-x)(${conc5bval}-x)}}$`"
        />
      </p>

      <calculation-input
        v-model="inputs.x5"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question350',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        x1: null,
        x2: null,
        x3: null,
        x4: null,
        x5: null,
      },
    };
  },
  computed: {
    c1() {
      return this.taskState.getValueBySymbol('c1').content;
    },
    c2() {
      return this.taskState.getValueBySymbol('c2').content;
    },
    b3() {
      return this.taskState.getValueBySymbol('b3').content;
    },
    coefb3() {
      return this.b3.toFloat().toFixed(1);
    },
    c3() {
      return this.taskState.getValueBySymbol('c3').content;
    },
    coefc3() {
      return this.c3.toFloat().toFixed(1);
    },
    K4() {
      return this.taskState.getValueBySymbol('K4').content;
    },
    K4val() {
      return this.K4.toFloat().toFixed(1);
    },
    conc4() {
      return this.taskState.getValueBySymbol('conc4').content;
    },
    conc4val() {
      return this.conc4.toFloat().toFixed(2);
    },
    K5() {
      return this.taskState.getValueBySymbol('K5').content;
    },
    K5val() {
      return this.K5.toFloat().toFixed(2);
    },
    conc5a() {
      return this.taskState.getValueBySymbol('conc5a').content;
    },
    conc5aval() {
      return this.conc5a.toFloat().toFixed(3);
    },
    conc5b() {
      return this.taskState.getValueBySymbol('conc5b').content;
    },
    conc5bval() {
      return this.conc5b.toFloat().toFixed(3);
    },
  },
};
</script>
